import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import axiosAPI from "../utils/axiosAPI";
import UserModel from "../models/user";

const useAuth = () => {
    const {auth, setAuth} = useContext(AuthContext);
    // const API = useAPI();

    const googleSignIn = async (credential: string) => {

        const response = await axiosAPI.post('/login-google', {
                credential: credential,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

        const token = response.data.token;
        const user = await getUser(token);
        setAuth({accessToken: token, user: user});
    }

    const googleSignUp = async (credential: string) => {

        const response = await axiosAPI.post('/signup-google', {
                credential: credential,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

        const token = response.data.token;
        const user = await getUser(token);
        setAuth({accessToken: token, user: user});
    }

    const login = async (email: string, password: string) => {
        const response = await axiosAPI.post('/login', {email, password},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });

        const token = response.data.token;
        const user = await getUser(token);
        setAuth({accessToken: token, user: user});
    }

    const signup = async (firstName: string, lastName: string, email: string, password: string) => {
        const response = await axiosAPI.post('/signup', {firstName, lastName, email, password},
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });

        const token = response.data.token;
        const user = await getUser(token);
        setAuth({accessToken: token, user: user});
    }

    const getUser = async (token: string) => {
        const response = await axiosAPI.get('/user/me', {
            headers: {
                'Content-Type': 'application/json',
                'token': token
            },
            withCredentials: true
        });

        const user = response.data;
        // console.log(user);
        return new UserModel(user);
    }

    const refresh = async () => {
        const response = await axiosAPI.get('/refresh', {
            withCredentials: true
        });
        // const response = await PublicAPI.getInstance().get('/refresh', {}, 'application/json', true);

        const token = response.data.token as string;

        const user = await getUser(token);

        setAuth(prev => {
            return {
                ...prev,
                user: user,
                accessToken: token
            }
        });

        return token;
    }

    const logout = async () => {
        try {
            await axiosAPI.get('/logout', {withCredentials: true});
            setAuth(undefined);
        } catch (error) {
            console.log(error);
        }
    }

    const isAuthenticated = () => {
        return typeof auth?.accessToken === 'string';
    }

    const isAdmin = () => {
        return auth?.user?.email === 'vikassurera@gmail.com';
    }

    return {
        auth,
        isAuthenticated,
        refresh,
        login,
        signup,
        logout,
        googleSignIn,
        googleSignUp,
        isAdmin
    }
}

export default useAuth;
