import {Outlet} from 'react-router-dom';
import {useEffect, useState} from "react";
import useAuth from "../hooks/useAuth";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {refresh,auth} = useAuth();

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (error) {

            } finally {
                isMounted && setIsLoading(false);
            }
        }

        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

        return () => {
            isMounted = false;
        }
    }, [auth?.accessToken, refresh]);

    return (
        <>
            {
                isLoading
                    ? <div className={'d-flex justify-content-center align-items-center'} style={{height: '100vh'}}>
                        <div className={'spinner-border'} role={'status'}>
                            <span className={'visually-hidden'}>Loading...</span>
                        </div>
                    </div>
                    : <Outlet/>
            }
        </>
    );
}

export default PersistLogin;