enum AlertType{
    warning = 'warning',
    error = 'danger',
    info = 'info',
    success = 'success'
}

interface AlertI{
    type: AlertType
    message: string
}

export default class AlertModel{
    alert: AlertI
    constructor(alert: AlertI) {
        this.alert = alert;
    }

    // getters
    message(): string | undefined{
        return this.alert?.message;
    }

    type(): AlertType | undefined{
        return this.alert?.type;
    }

    static error(message: string){
        return new AlertModel({type: AlertType.error, message: message});
    }

    static warning(message: string){
        return new AlertModel({type: AlertType.warning, message: message});
    }

    static info(message: string ){
        return new AlertModel({type: AlertType.info, message: message});
    }

    static success(message: string){
        return new AlertModel({type: AlertType.success, message: message});
    }
}