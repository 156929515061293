import React from 'react';
import './App.scss';
import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Error404Page from "./pages/Error404Page";
import LandingPage from "./pages/LandingPage";
import AppInfoPage from "./pages/AppInfoPage";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import HomePage from "./pages/HomePage";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="/" element={<HomePage/>}/>
            </Route>
            {/*<Route element={<PersistLogin/>}>*/}
            {/*    <Route element={<EnforceLoginForwarding/>}>*/}
            {/*        <Route path="/login" element={<LoginPage/>}/>*/}
            {/*    </Route>*/}
            {/*</Route>*/}
            <Route path="/" element={<Layout/>}>
                <Route path="/info" element={<AppInfoPage/>}/>

                <Route element={<PersistLogin/>}>
                    <Route element={<RequireAuth/>}>
                        {/*<Route element={<RequireAdmin/>}>*/}
                        {/*    <Route path="/admin" element={<LandingPage/>}/>*/}
                        {/*</Route>*/}
                        <Route path="/dashboard" element={<LandingPage/>}/>
                    </Route>
                </Route>

                <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>

                {/*Catch all route*/}
                <Route path="*" element={<Error404Page/>}/>
            </Route>
        </Routes>
    );
}

export default App;

