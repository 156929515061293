import axios from "axios";
import {AxiosError} from "axios";

const axiosAPI = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const extractErrorMessages = (error: Error | AxiosError | unknown) => {
    let message = "Something went wrong";
    if(error instanceof AxiosError){
        message = error.response?.data.message;
    }else if(error instanceof Error){
        message = error.message;
    }
    return message;
}

export default axiosAPI;