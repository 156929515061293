import React from "react";
import {GoVerified} from "react-icons/go";

import userAlert from "../hooks/userAlert";
import CustomAlert from "./CustomAlert";
import {LoadingIndicatorDots} from "./LoadingIndicators";
import useVerification from "../hooks/useVerification";
import {extractErrorMessages} from "../utils/axiosAPI";

enum VerificationStage {
    EMAIL_INPUT,
    VERIFICATION_CODE_INPUT,
    VERIFIED
}

interface EmailVerificationProps {
    className?: string
    onVerified: () => void
    showEnterCode?: boolean
}

const EmailVerification = ({className, onVerified, showEnterCode}: EmailVerificationProps) => {
    const {alert, setError, setWarning} = userAlert();
    const {sendCode, verifyCode} = useVerification();

    const [instiEmail, setInstiEmail] = React.useState('');
    const [verificationCode, setVerificationCode] = React.useState('');
    const [stage, setStage] = React.useState(showEnterCode ? VerificationStage.VERIFICATION_CODE_INPUT : VerificationStage.EMAIL_INPUT);
    const [loading, setLoading] = React.useState(false);

    const handleInstiEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInstiEmail(event.target.value);
    }

    const handleVerificationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // must be a number discarding any other character
        const value = event.target.value;
        if (value.match(/^[0-9]*$/) && value.length <= 6) setVerificationCode(value);
    }

    const validateEmail = (email: string): boolean => {
        const trimmedEmail = email.trim();

        if (trimmedEmail.length === 0) {
            setWarning('Please fill in all fields');
            return false;
        }
        // check if email is valid and its domain is from the list of allowed domains
        const emailParts = trimmedEmail.split('@');
        if (emailParts.length !== 2) {
            setWarning('Please enter a valid email');
            return false;
        }

        return true;
    }

    const validateVerificationCode = (code: string) => {
        const trimmedCode = code.trim();
        if (trimmedCode.length === 0) {
            setWarning('Please fill in all fields');
            return false;
        }

        // verification code must be 6 digits
        if (trimmedCode.length !== 6) {
            setWarning('Verification code must be 6 digits');
            return false;
        }
        return true;
    }

    const _sendVerificationCode = async () => {
        setLoading(true);

        try {
            await sendCode(instiEmail);
            setStage(VerificationStage.VERIFICATION_CODE_INPUT);
        } catch (error) {
            console.log(error);
            setError(extractErrorMessages(error));
        } finally {
            setLoading(false);
        }
    }

    const _verifyCode = async () => {
        setLoading(true);

        try {
            await verifyCode(verificationCode);
            setStage(VerificationStage.VERIFIED);
        } catch (error) {
            console.log(error);
            setError(extractErrorMessages(error));
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const handleInstiEmailSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(instiEmail);

        if (validateEmail(instiEmail)) {
            console.log('Valid Email');
            _sendVerificationCode();
        }
    }

    const handleVerificationCodeSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(verificationCode);

        if (validateVerificationCode(verificationCode)) {
            console.log('Valid Code');
            _verifyCode();
        }
    }

    return <div className={`my-2 ${className ?? ''}`}>
        {
            stage !== VerificationStage.VERIFIED && <h4>Verify Yourself</h4>
        }
        <CustomAlert alert={alert}/>
        {
            loading && <LoadingIndicatorDots className={'text-center m-2'}/>
        }
        {
            stage === VerificationStage.EMAIL_INPUT &&
            <form className={'form-inline'} onSubmit={handleInstiEmailSubmit}>
                <p>
                    Please enter your <strong>institute email</strong> to verify yourself. (valid for 5 mins only)
                </p>
                <input type={'email'} className={'form-control mr-2'}
                       placeholder={'sample@institute.com'}
                       value={instiEmail}
                       onChange={handleInstiEmailChange}/>
                <button disabled={loading} className={'btn btn-primary buy-cta mt-4'}>Send Code</button>
            </form>
        }

        {
            stage === VerificationStage.VERIFICATION_CODE_INPUT &&
            <form className={'form-inline'} onSubmit={handleVerificationCodeSubmit}>
                <p>
                    Enter the <strong>verification code</strong> sent to <span
                    className={'text-primary'}>{instiEmail}</span>
                </p>
                <input type={'text'} className={'form-control mr-2'}
                       placeholder={'123456'}
                       value={verificationCode}
                       onChange={handleVerificationCodeChange}/>
                <button disabled={loading} className={'btn btn-primary buy-cta mt-4'}>Verify</button>
            </form>
        }

        {
            stage === VerificationStage.VERIFIED && <div className={'text-center'}>
                <h5 className={'text-success'}><GoVerified/> Email Verified</h5>
                <p className={'text-muted'}>You can now buy an Oreo</p>

                <button className={'btn btn-success buy-cta mt-4'} onClick={onVerified}>Done</button>
            </div>
        }
    </div>
}

export default EmailVerification;