import useAPI from "./useAPI";

interface useVerificationHook {
    sendCode: (instiEmail: string) => Promise<void>;
    verifyCode: (code: string) => Promise<void>;
    getAllowedDomains: () => Promise<string[]>;
}

const useVerification = (): useVerificationHook => {
    const API = useAPI();

    const sendCode = async (instiEmail: string) => {
        const body = {
            instiEmail: instiEmail,
        }

        await API.postRequest('/user/send-code', undefined, body);
    };

    const verifyCode = async (code: string) => {
        const body = {
            code: code,
        }

        await API.postRequest('/user/verify-code', undefined, body);
    };

    const getAllowedDomains = async () => {
        const response = await API.getRequest('/config/allowed-domains', undefined);
        const result = response.data;
        return result;
    }

    return {
        sendCode,
        verifyCode,
        getAllowedDomains
    }
}

export default useVerification;
