import React, {createContext, PropsWithChildren, useState} from "react";
import UserModel from "../models/user";

interface AuthI{
    user?: UserModel,
    accessToken: string,
}

export interface AuthContextI {
    auth?: AuthI | undefined,
    setAuth:  React.Dispatch<React.SetStateAction<AuthI|undefined>>
}

const AuthContext = createContext<AuthContextI>({
    setAuth: () => {}
});

export const AuthProvider = ({children}: PropsWithChildren) => {
    const [auth, setAuth] = useState<AuthI|undefined>(undefined);

    return (
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContext;