import {Modal, ModalBody} from "reactstrap";
import EmailVerification from "./EmailVerification";

interface EmailVerificationModalProps {
    isOpen: boolean
    toggle: () => void
    showEnterCode?: boolean

    onVerificationSuccess: () => void
}

const EmailVerificationModal = ({isOpen, toggle, onVerificationSuccess,showEnterCode}: EmailVerificationModalProps) => {

    const onVerified = () => {
        toggle();
        onVerificationSuccess();
    }

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalBody className={'p-4'}>
            <EmailVerification onVerified={onVerified} showEnterCode={showEnterCode}/>
        </ModalBody>
    </Modal>
}

export default EmailVerificationModal;