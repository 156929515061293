import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import axiosAPI from "../utils/axiosAPI";
import {AxiosResponse} from "axios";

export interface APIHook {
    postRequest: (route: string, queryParams: UrlQueryParam | undefined, data: ApiBodyData, contentType?: string) => Promise<AxiosResponse>;
    putRequest: (route: string, queryParams: UrlQueryParam | undefined, data: ApiBodyData, contentType?: string) => Promise<AxiosResponse>;
    getRequest: (route: string, queryParams: UrlQueryParam | undefined, contentType?: string) => Promise<AxiosResponse>;
    deleteRequest: (route: string, queryParams: UrlQueryParam, contentType?: string) => Promise<AxiosResponse>;
}

interface UrlQueryParam {
    [key: string]: string;
}

interface ApiBodyData {
    [key: string]: string | number | boolean | undefined | object;
}


const useAPI = (): APIHook => {
    const {auth} = useContext(AuthContext);
    const baseUrl = process.env.REACT_APP_API_URL;

    const buildUrl = (url: string, params: UrlQueryParam | undefined) => {
        if (params === undefined) {
            return url;
        }
        const query = Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
        return baseUrl + url + '?' + query;
    }

    const buildHeaders = (contentType?: string) => {
        return {
            'Content-Type': contentType ?? 'application/json',
            'token': auth?.accessToken
        }
    }

    const postRequest = async (route: string, queryParams: UrlQueryParam | undefined, data: ApiBodyData, contentType?: string): Promise<AxiosResponse> => {
        return axiosAPI.post(buildUrl(route, queryParams), data, {headers: buildHeaders(contentType)})
    }

    const putRequest = async (route: string, queryParams: UrlQueryParam | undefined, data: ApiBodyData, contentType?: string): Promise<AxiosResponse> => {
        return axiosAPI.put(buildUrl(route, queryParams), data, {headers: buildHeaders(contentType)})
    }

    const getRequest = async (route: string, queryParams: UrlQueryParam | undefined, contentType?: string): Promise<AxiosResponse> => {
        return axiosAPI.get(buildUrl(route, queryParams), {headers: buildHeaders(contentType)})
    }

    const deleteRequest = async (route: string, queryParams: UrlQueryParam, contentType?: string): Promise<AxiosResponse> => {
        return axiosAPI.delete(buildUrl(route, queryParams), {headers: buildHeaders(contentType)})
    }

    return {
        postRequest,
        putRequest,
        getRequest,
        deleteRequest
    }
}

export default useAPI;
