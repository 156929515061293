import {Outlet} from "react-router-dom";
import React from "react";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Layout = () => {
    return <div>
        <NavBar/>
        <Outlet/>
        <Footer/>
    </div>
}

export default Layout;