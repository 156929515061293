import React from "react";
import {Badge} from "reactstrap";
import useAuth from "../hooks/useAuth";
import {GoVerified} from "react-icons/go";
import EmailVerificationModal from "../components/EmailVerificationModal";
// import BuySubscription from "../components/BuySubscription";
import DownloadAppButton from "../components/DownloadAppButton";
// import WhatsappVerificationModal from "../components/WhatsappVerificationModal";
// import WhatsappPaymentSection from "../components/WhatsappPaymentSection";
// import {WhatsappPaymentStatus, WhatsappTrialStatus} from "../models/user";
// import CustomAlert from "../components/CustomAlert";
import moment from "moment";

enum SubModalType {
    EMAIL_VERIFY = 'EMAIL_VERIFY',
    WHATSAPP_VERIFY = 'WHATSAPP_VERIFY',
}

const LandingPage = () => {
    const user = useAuth().auth?.user;

    const [subModalType, setSubModalType] = React.useState<SubModalType | null>(null);
    const toggleSubModal = (type?: SubModalType) => {
        setSubModalType(type ?? null);
    }

    // reload the page when the user is verified
    const reloadPage = () => {
        window.location.reload();
    }

    return <div className={'page-container'}>
        {
            subModalType === SubModalType.EMAIL_VERIFY && <EmailVerificationModal isOpen={true}
                                                                                  showEnterCode={user?.showEnterCode()}
                                                                                  onVerificationSuccess={reloadPage}
                                                                                  toggle={toggleSubModal}/>
        }
        {/*{*/}
        {/*    subModalType === SubModalType.WHATSAPP_VERIFY && <WhatsappVerificationModal isOpen={true}*/}
        {/*                                                                                showEnterCode={user?.showEnterCode()}*/}
        {/*                                                                                onVerificationSuccess={reloadPage}*/}
        {/*                                                                                toggle={toggleSubModal}/>*/}
        {/*}*/}
        <div className={'container'}>
            <div className={'login-inner p-4'}>
                <div className={'card card-cover'}>
                    <div className={'p-4'}>
                        {
                            user && <>
                                <div className={'user-card-header'}>
                                    <img src={user?.avatarUrl ?? 'placeholder.png'} alt="user" className=''/>
                                    <div>
                                        <h4 className={'card-title'}>
                                            {user?.name}
                                            <span style={{fontSize: '18px'}}>
                                    &nbsp;
                                                <GoVerified color={user.verified ? 'blue' : 'danger'}/>
                                                &nbsp;
                                                <Badge color={user.paid ? 'success' : 'danger'}>
                                    {user.paid ? 'Subscribed' : 'Unsubscribed'}
                                </Badge>
                                </span>
                                        </h4>
                                        <p>
                                            {user?.email}
                                        </p>
                                    </div>
                                </div>

                                <br/>
                                <br/>

                                {/*<EmailVerification/>*/}
                                {
                                    !user.isVerified() &&
                                    <>
                                        <div className='buy-item'>
                                            {/*<div className='buy-item-logo'>*/}
                                            {/*    <img alt="ERP Alert Logo"*/}
                                            {/*         src="oreo2.png"/>*/}
                                            {/*</div>*/}
                                            <div className='buy-item-details'>
                                                <h5>
                                                    Verification Needed!
                                                </h5>
                                                <p>
                                                    Please verify your institution email to get payment access.
                                                </p>
                                            </div>

                                        </div>
                                        <button className={'btn btn-primary buy-cta'}
                                                onClick={() => toggleSubModal(SubModalType.EMAIL_VERIFY)}>
                                            Verify Insti Email
                                        </button>
                                    </>
                                }

                                {/*<div className={'mt-4'}>*/}
                                {/*    <h5>Buy oreo for there features</h5>*/}
                                {/*    <ul style={{textAlign: 'left'}}>*/}
                                {/*        <li>Access notifications directly from your mobile device</li>*/}
                                {/*        <li>Push notifications of every new notice</li>*/}
                                {/*        <li>No need to login again and again for notices</li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                {
                                    // user.showBuy() && <BuySubscription onSubscriptionSuccess={reloadPage}/>
                                    // <BuySubscription onSubscriptionSuccess={reloadPage}/>
                                }

                                {/*Disabled the buy feature*/}
                                <div className='buy-item'>
                                    <div className='buy-item-logo'>
                                        <img alt="ERP Alert Logo"
                                             src="oreo2.png"/>
                                    </div>
                                    <div className='buy-item-details'>
                                        <h5>
                                            ERP Alerts App
                                        </h5>
                                        <p>
                                            Subscription for ERP Alerts App
                                        </p>
                                    </div>

                                </div>
                                {/*<button disabled={true} className='btn btn-primary buy-cta' >*/}
                                {/*    Buy Now (&#x20B9;70)*/}
                                {/*</button>*/}
                                <p className={'text-danger'}>Payments are disabled due to technical issues</p>

                                {/*{*/}
                                {/*    user.showPaid() && <div className='buy-item'>*/}
                                {/*        <div className='buy-item-logo'>*/}
                                {/*            <img alt="ERP Alert Logo"*/}
                                {/*                 src="oreo2.png"/>*/}
                                {/*        </div>*/}
                                {/*        <div className='buy-item-details'>*/}
                                {/*            <h5>*/}
                                {/*                ERP Alerts App*/}
                                {/*            </h5>*/}
                                {/*            <p>*/}
                                {/*                You have subscribed for ERP Alerts App*/}
                                {/*            </p>*/}
                                {/*        </div>*/}

                                {/*    </div>*/}
                                {/*}*/}
                                <br/>
                                <br/>
                                <div className='buy-item'>
                                    <div className='buy-item-logo'>
                                        <img alt="ERP Alert Logo"
                                             src="erp_whatsapp.png"/>
                                    </div>
                                    <div className='buy-item-details'>
                                        <h5>
                                            ERP Whatsapp Alerts
                                        </h5>
                                        <p>
                                            Get subscription for ERP Whatsapp Alerts.
                                        </p>
                                    </div>

                                </div>
                                <ul>
                                    <li>You are on free trial.</li>
                                    <li>Trial Expiry: <span className={'text-danger'}>{ moment(user.whatsapp?.trialExpiry).format('LLL')}</span></li>
                                    <li>
                                        You can buy a subscription after trial expiry.
                                    </li>
                                    <li>Subscription will be valid for <span className={'text-danger'}>1 month (30 days)</span> only starting from
                                        payment date.
                                    </li>
                                    <li>You would need to renew the subscription after 1 month at <span
                                        className={'text-danger'}>&#x20B9;50</span>.
                                    </li>
                                </ul>
                                <p className={'text-danger'}>Payments are disabled due to technical issues</p>

                                {/*<Whatsapp Verification/>*/}
                                {/*{*/}
                                {/*    user.isVerified() && user.getTrialStatus() === WhatsappTrialStatus.unTrial &&*/}
                                {/*    <>*/}
                                {/*        <div className='buy-item'>*/}
                                {/*            <div className='buy-item-logo'>*/}
                                {/*                <img alt="ERP Alert Logo"*/}
                                {/*                     src="erp_whatsapp.png"/>*/}
                                {/*            </div>*/}
                                {/*            <div className='buy-item-details'>*/}
                                {/*                <h5>*/}
                                {/*                    ERP Whatsapp Alerts*/}
                                {/*                </h5>*/}
                                {/*                <p>*/}
                                {/*                    Please verify your whatsapp to get payment access for ERP Whatsapp Alerts*/}
                                {/*                </p>*/}
                                {/*            </div>*/}

                                {/*        </div>*/}
                                {/*        <button className={'btn btn-primary buy-cta'}*/}
                                {/*                onClick={() => toggleSubModal(SubModalType.WHATSAPP_VERIFY)}>*/}
                                {/*            Add Whatsapp*/}
                                {/*        </button>*/}
                                {/*    </>*/}
                                {/*}*/}

                                {/*{*/}
                                {/*    user.isVerified() && user.getTrialStatus() !== WhatsappTrialStatus.unTrial && <WhatsappPaymentSection user={user} onSubscriptionSuccess={reloadPage}/>*/}
                                {/*}*/}
                                <br/>
                                <br/>
                                <div className={'mt-4 text-center'}>
                                    <h5>Download the App</h5>
                                    <DownloadAppButton/>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LandingPage;