import {useCallback, useEffect, useState} from "react";
import AlertModel from "../models/alert";

interface useAlertI {
    alert: AlertModel | undefined
    resetAlert: () => void
    setError: (message: string) => void
    setWarning: (message: string) => void
    setInfo: (message: string) => void
    setSuccess: (message: string) => void
}

const useAlert = (): useAlertI => {
    const [alert, setAlert] = useState<AlertModel | undefined>(undefined);

    useEffect(() => {
        if(alert){
            setTimeout(() => {
                setAlert(undefined);
            }, 3000);
        }
    }, [alert]);

    const setError = useCallback((message?: string) => {
        const defaultMsg = 'Something went wrong';
        setAlert(AlertModel.error(message ?? defaultMsg));
    }, []);

    const setWarning = useCallback((message: string) => {
        setAlert(AlertModel.warning(message));
    }, []);

    const setInfo = useCallback((message: string) => {
        setAlert(AlertModel.info(message));
    }, []);

    const setSuccess = useCallback((message: string) => {
        setAlert(AlertModel.success(message));
    }, []);

    const resetAlert = useCallback(() => {
        setAlert(undefined);
    }, []);

    return {alert, resetAlert, setError, setWarning, setInfo, setSuccess};
}

export default useAlert;