import {Spinner} from "reactstrap";
import React from "react";

export const LoadingIndicatorSpinner = () => {
    // bootstrap spinner
    return <div>
        {/*<Spinner animation="grow" variant="primary" />*/}
        <Spinner animation="grow" variant="success"/>
        {/*<Spinner animation="grow" variant="primary" />*/}
    </div>
}

export interface LoadingIndicatorProps {
    className?: string
}

export const LoadingIndicatorDots = ({className}: LoadingIndicatorProps) => {
    return <div className={className}>
        <div className="spinner-grow text-primary spinner-grow-sm" role="status">
            {/*<span className="sr-only">Loading...</span>*/}
        </div>
        <div className="spinner-grow text-success spinner-grow-sm" role="status">
            {/*<span className="sr-only">Loading...</span>*/}
        </div>
        <div className="spinner-grow text-primary spinner-grow-sm" role="status">
            {/*<span className="sr-only">Loading...</span>*/}
        </div>
    </div>
}