import {Alert} from "reactstrap";
import React from "react";
import AlertModel from "../models/alert";

interface CustomAlertProps {
    alert: AlertModel | undefined
    className?: string
}

const CustomAlert = ({alert, className}: CustomAlertProps) => {
    return alert ?
        <Alert color={alert.type()} className={className}>
            {alert.message()}
        </Alert>
        :
        <div></div>;
}

export default CustomAlert;