import {Link} from "react-router-dom";

const Error404Page = () => {
    return <div className={'page-container'}>
        <div className="container">
            <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"><span className="text-danger">Opps!</span> Page not found.</p>
                <p className="lead">
                    The page you’re looking for doesn’t exist.
                </p>
                <Link to={'/'} className="btn btn-primary">Go Home</Link>
            </div>
        </div>
    </div>
}

export default Error404Page;