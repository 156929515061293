import React from "react";
import {FaGooglePlay} from "react-icons/fa";
import {AiOutlineLogin} from "react-icons/ai";
import {BiTimer} from "react-icons/bi";
import {HiOutlineBell} from "react-icons/hi";
import { BsFileEarmarkPdf} from "react-icons/bs";

const HomePage = () => {
    return <div className='page-container'>
        <div className={'container'}>
            <div className="px-4 pt-5 my-5 text-center border-bottom">
                <h1 className="display-4 fw-bold">ERP Alerts</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        A one stop solution for all your ERP related alerts. Get notified about your ERP notices in
                        real-time.
                    </p>
                    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
                        {/*<Link to={'/login'} type="button" className="btn btn-primary btn-lg px-5 me-sm-3">Login</Link>*/}
                        <a className={'download-app-cta-bg'} rel={'noreferrer'} target={'_blank'}
                           href='https://play.google.com/store/apps/details?id=com.vikassurera.erpalerts&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img alt='Get it on Google Play'
                                 src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                        </a>
                        {/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Secondary</button>*/}
                    </div>
                </div>
                <div className="overflow-hidden" style={{maxHeight: "30vh"}}>
                    <div className="container px-5">
                        <img src="banner.png" className="img-fluid border rounded-3 shadow-lg mb-4"
                             alt="ERP Alerts Banner" width="700" height="500" loading="lazy"/>
                    </div>
                </div>
            </div>
        </div>
        <div className="container py-3 inner-page">
            <div className={'section'}>
                <h2 className="pb-2 border-bottom text-center">Features</h2>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-4 custom-row pt-3">
                    <div className="col d-flex align-items-start">
                        <div>
                            <BiTimer/>
                        </div>
                        <div>
                            <h3 className="fw-bold mb-0 fs-4">Real-time</h3>
                            <p>Notices are based on real-time</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div><HiOutlineBell/></div>
                        <div>
                            <h3 className="fw-bold mb-0 fs-4">Push Notification</h3>
                            <p>Get the alerts in your device</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div><AiOutlineLogin/></div>
                        <div>
                            <h3 className="fw-bold mb-0 fs-4">No frequent login</h3>
                            <p>No need to login again and again</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div><FaGooglePlay/></div>
                        <div>
                            <h3 className="fw-bold mb-0 fs-4">Android App</h3>
                            <p>No website login to check notifications</p>
                        </div>
                    </div>
                    <div className="col d-flex align-items-start">
                        <div><BsFileEarmarkPdf/></div>
                        <div>
                            <h3 className="fw-bold mb-0 fs-4">Attachments</h3>
                            <p>No requirement to download file externally</p>
                        </div>
                    </div>
                    {/*<div className="col d-flex align-items-start">*/}
                    {/*    <div><BsWhatsapp/></div>*/}
                    {/*    <div>*/}
                    {/*        <h3 className="fw-bold mb-0 fs-4">Whatsapp Alerts</h3>*/}
                    {/*        <p>Get alerts directly into your whatsapp chats</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            {/*<div className={'section'}>*/}
            {/*    <h2 className="text-center">Products</h2>*/}
            {/*    <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4">*/}
            {/*        <div className="col">*/}
            {/*            <div*/}
            {/*                className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg custom-bg-color">*/}
            {/*                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">*/}
            {/*                    <h2 className="pt-5 mb-4 lh-1 fw-bold">Alerts App</h2>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="col">*/}
            {/*            <div*/}
            {/*                className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg custom-bg-color">*/}
            {/*                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">*/}
            {/*                    <h2 className="pt-5 mb-4 lh-1 fw-bold">*/}
            {/*                        Whatsapp Alerts*/}
            {/*                    </h2>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}

            {/*        <div className="col">*/}
            {/*            <div*/}
            {/*                className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg custom-bg-color">*/}
            {/*                <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">*/}
            {/*                    <h2 className="pt-5 mb-4 lh-1 fw-bold">*/}
            {/*                        Alerts Website*/}
            {/*                    </h2>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'section'}>
                <h2 className="pb-2 text-center">Pricing</h2>
                <div className="row row-cols-1 row-cols-md-3 text-center">
                    {/*<div className="col">*/}
                    {/*    <div className="card mb-4 rounded-3 shadow-sm">*/}
                    {/*        <div className="card-header py-3">*/}
                    {/*            <h4 className="my-0 fw-normal">ERP Whatsapp Alerts</h4>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <h1 className="card-title pricing-card-title">₹50<small*/}
                    {/*                className="text-muted fw-light">/month</small></h1>*/}
                    {/*            <p>*/}
                    {/*                <s style={{fontSize: '20px'}}>*/}
                    {/*                    ₹150<small>/month</small>*/}
                    {/*                </s>*/}
                    {/*            </p>*/}
                    {/*            <ul className="list-unstyled mt-3 mb-4">*/}
                    {/*                <li>2 day free trial</li>*/}
                    {/*                <li>Whatsapp alerts</li>*/}
                    {/*                <li>Real-time notices</li>*/}
                    {/*                <li>Attachment Support (new)</li>*/}
                    {/*            </ul>*/}
                    {/*            /!*<button type="button" className="w-100 btn btn-lg btn-outline-primary">*!/*/}
                    {/*            /!*    Login*!/*/}
                    {/*            /!*</button>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col">
                        <div className="card mb-4 rounded-3 shadow-sm border-primary">
                            <div className="card-header py-3 text-bg-primary border-primary">
                                <h4 className="my-0 fw-normal">ERP Alerts App</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">₹99<small
                                    className="text-muted fw-light">/sem</small></h1>
                                <p>
                                    <s style={{fontSize: '20px'}}>
                                        ₹499/month
                                    </s>
                                </p>
                                <ul className="list-unstyled mt-3 mb-4">
                                    <li>Push notification</li>
                                    <li>Real-time notices</li>
                                    <li>Skip the re-login hassle</li>
                                    <li>Get attachments</li>
                                    <li>Share notice with others</li>
                                </ul>
                                {/*<button type="button" className="w-100 btn btn-lg btn-primary">Login</button>*/}
                            </div>
                        </div>
                    </div>
                    {/*<div className="col">*/}
                    {/*    <div className="card mb-4 rounded-3 shadow-sm">*/}
                    {/*        <div className="card-header py-3">*/}
                    {/*            <h4 className="my-0 fw-normal">More Products</h4>*/}
                    {/*        </div>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <h1 className="card-title pricing-card-title"><small*/}
                    {/*                className="text-muted fw-light">Soon</small></h1>*/}
                    {/*            <ul className="list-unstyled mt-3 mb-4">*/}
                    {/*                <li>New and exciting</li>*/}
                    {/*                <li>Features</li>*/}
                    {/*                <li>Coming Soon</li>*/}
                    {/*                <li>Stay tuned</li>*/}
                    {/*            </ul>*/}
                    {/*            /!*<button type="button" className="w-100 btn btn-lg btn-outline-primary">*!/*/}
                    {/*            /!*    Login*!/*/}
                    {/*            /!*</button>*!/*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    </div>
}

export default HomePage;