import React from 'react';
// import useAuth from "../hooks/useAuth";
// import { useNavigate} from "react-router-dom";

const Navbar = () => {
    // const { logout} = useAuth();
    // const navigate = useNavigate();

    // const handleSignOut = async () => {
    //     try {
    //         await logout()
    //
    //         // navigate to home page
    //         navigate('/');
    //
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return (
        <nav className="navbar navbar-light bg-transparent navbar-expand-md">
            <div className={'container'}>
                <a className="navbar-brand" href="/">
                    <img src="icon3.png" className={'rounded'} width="40" height="40" alt=""/>
                    &nbsp;
                    <span>ERP Alerts</span>
                </a>
                <div className={'nav-profile'}>
                    <a style={{textDecoration: 'none', marginLeft: '10px'}}
                       href={'https://github.com/vikassurera/erp-alerts-app-public'}>
                        GitHub
                    </a>
                    <a style={{textDecoration: 'none', marginLeft: '10px'}}
                       href={'https://chromewebstore.google.com/detail/erp-alerts/hnnjfmjhhdmajofafjndhpmohfodakco'}>Chrome
                        Extension</a>
                    {/*<img src={auth?.user?.avatarUrl ?? 'placeholder.png'} alt="user" className='user-img'/>*/}
                    {/*&nbsp;*/}
                    {/*<div className={'nav-profile-data'}>*/}
                    {/*    {*/}
                    {/*        auth?.user?.name !== null && <h6>*/}
                    {/*            {auth?.user?.name}*/}
                    {/*        </h6>*/}
                    {/*    }*/}
                    {/*    /!*<p>*!/*/}
                    {/*    /!*    {auth?.user?.email}*!/*/}
                    {/*    /!*</p>*!/*/}
                    {/*</div>*/}
                    {/*&nbsp;*/}
                    {/*&nbsp;*/}
                    {/*{*/}
                    {/*    auth?.user  ?*/}
                    {/*        <button className={'btn btn-outline-primary'} onClick={handleSignOut}>*/}
                    {/*            Logout*/}
                    {/*        </button>*/}
                    {/*        :*/}
                    {/*        <Link className={'btn btn-primary px-4'} to={'/login'}>*/}
                    {/*            Login*/}
                    {/*        </Link>*/}
                    {/*}*/}
                </div>
            </div>
        </nav>
        //   <div className='nav-bar-custom'>

        //
        //       {auth?.user?.firstName ? (
        //           <button className='btn btn-primary' onClick={handleSignOut}>Logout</button>
        //       ) : <div></div>}
        //       {/* <h1 className='text-center text-2xl font-bold'>
        //   Firebase Google Auth & Context
        // </h1>
        // {user?.displayName ? (
        //   <button onClick={handleSignOut}>Logout</button>
        // ) : (
        //   <Link to='/signin'>Sign in</Link>
        // )} */}
        //   </div>
    );
};

export default Navbar;