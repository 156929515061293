import moment from "moment";

interface IUser {
    id: string;
    email: string;
    name: string
    avatarUrl?: string;
    verified?: boolean | null;
    paid?: boolean | null;
    expiry?: number,
    whatsapp?: {
        number: string;
        trial: boolean;
        trialExpiry: number | undefined
        verified: boolean,
    },
    whatsappPayment?: {
        paid: boolean,
        expiry: number | undefined,
    }
}

export enum WhatsappTrialStatus {
    unTrial = 'unTrial',
    onTrial = 'onTrial',
    trialExpired = 'trialExpired',
}

export enum WhatsappPaymentStatus {
    unpaid = 'unpaid',
    paid = 'paid',
    paymentExpired = 'paymentExpired',
}

export default class UserModel {
    id: string;
    email: string;
    name: string;
    verified: boolean;
    paid: boolean;
    avatarUrl?: string;
    expiryDate?: Date;

    whatsapp?: {
        number: string;
        trial: boolean;
        trialExpiry: Date | undefined
        verified: boolean,
    }

    whatsappPayment?: {
        paid: boolean,
        expiry: Date | undefined,
    }

    constructor(user: IUser) {
        this.id = user.id;
        this.email = user.email;
        this.name = user.name;
        this.verified = user.verified === true;
        this.paid = user.paid === true;
        this.avatarUrl = user.avatarUrl;

        if (user.expiry) {
            this.expiryDate = moment.unix(user.expiry).toDate();
        }

        if (user.whatsapp) {
            this.whatsapp = {
                number: user.whatsapp.number,
                trial: user.whatsapp.trial,
                verified: user.whatsapp.verified,
                trialExpiry: user.whatsapp.trialExpiry ? moment.unix(user.whatsapp.trialExpiry).toDate() : undefined
            }
        }

        if (user.whatsappPayment) {
            this.whatsappPayment = {
                paid: user.whatsappPayment.paid,
                expiry: user.whatsappPayment.expiry ? moment.unix(user.whatsappPayment.expiry).toDate() : undefined
            }
        }
    }

    isExpired(): boolean {
        if (!this.expiryDate) return true;
        return moment().isAfter(this.expiryDate);
    }

    isVerified(): boolean {
        return this.verified;
    }

    isPaid(): boolean {
        return this.paid;
    }

    showEnterCode(): boolean {
        if (!this.expiryDate) return false;
        return moment().isBefore(this.expiryDate);
    }

    showBuy(): boolean {
        return !this.isPaid() && this.isVerified();
    }

    showPaid(): boolean {
        return this.isPaid() && this.isVerified();
    }

    showWhatsappVerifiedOption(): boolean {
        if (!this.whatsapp) return true;
        return !this.whatsapp.verified;
    }

    getTrialStatus(): WhatsappTrialStatus {
        if(!this.whatsapp) return WhatsappTrialStatus.unTrial;
        if(!this.whatsapp.verified) return WhatsappTrialStatus.unTrial;

        if(this.whatsapp.trial) {
            if(!this.whatsapp.trialExpiry) return WhatsappTrialStatus.onTrial;
            if(moment().isBefore(this.whatsapp.trialExpiry)) return WhatsappTrialStatus.onTrial;
            return WhatsappTrialStatus.trialExpired;
        }else{
            return WhatsappTrialStatus.unTrial;
        }
    }

    getPaymentStatus(): WhatsappPaymentStatus {
        if(!this.whatsappPayment) return WhatsappPaymentStatus.unpaid;
        if(this.whatsappPayment.paid) {
            const expiry = this.whatsappPayment.expiry;
            if(!expiry) return WhatsappPaymentStatus.unpaid;
            if(moment().isAfter(expiry)) return WhatsappPaymentStatus.paymentExpired;
            return WhatsappPaymentStatus.paid;
        }else{
            return WhatsappPaymentStatus.unpaid;
        }
    }

    showBuyOption(): boolean {
        const trialStatus = this.getTrialStatus();
        const paymentStatus = this.getPaymentStatus();

        return (trialStatus === WhatsappTrialStatus.trialExpired) && (paymentStatus === WhatsappPaymentStatus.unpaid || paymentStatus === WhatsappPaymentStatus.paymentExpired);
    }
}